import React, { useEffect } from 'react'
import Header from '../../Component/Navbar/index'
import MostMediaExperienced from '../../Component/MostMediaExperienced'
import InfluencerMarketingSection from '../../Component/influencerMarkteing'
import OurMilestonesSection from '../../Component/OurMilestones'
import BrandGrid from '../../Component/Brands'
import FeaturedIn from '../../Component/Featured'
import GetInTouch from '../../Component/footer2/GetInTouch'
import ProductWebsites from './productWebsite/product_website'
import ServicesSection from './ServicesSection/services_section'
import { useDispatch, useSelector } from 'react-redux'
import { fetchHomepage } from '../../redux/homepageSlice'

const Home = () => {
  const dispatch = useDispatch()
  useEffect(()=>{
    console.log("fetchHomepage");
    dispatch(fetchHomepage())
 
  },[])
  const featuredMarketingData = useSelector((state)=>state.homepage.FeaturedMarketing)
  const brandsData = useSelector((state)=>state.homepage?.brands)

  console.log("featuredMarketingData",featuredMarketingData)

  return (
    <>
    <Header/>
    <MostMediaExperienced/>
    <InfluencerMarketingSection/>
    <OurMilestonesSection/>
    <ProductWebsites/>
    <BrandGrid brandsData={brandsData}/>
    <ServicesSection/>
    <FeaturedIn featuredMarketingData={featuredMarketingData}/>
    </>
  )
}

export default Home
