import axios from "axios";
import { APISURL } from "./apiUrl";


export const api = axios.create({
    baseURL: APISURL.BASE_URL,
});


const unauthError = () => {
    localStorage.clear();
    // window.location.href = '/auth/login'
}


export const handleGetHomePage = async () => {
    try {
        const res = await api.get(APISURL.GET_HOMEPAGE);
        return res;
    } catch (error) {
        unauthError();  console.error(error);
    }
}


//// about page

export const handleGetAboutPage = async () => {
    try {
        const res = await api.get(APISURL.GET_ABOUTPAGE);
        return res;
    } catch (error) {
        unauthError();  console.error(error);
    }
}


