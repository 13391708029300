import React from 'react';
import FeaturedIn from '../../../Component/Featured';
import { useSelector } from 'react-redux';

const FeaturedInSection = () => {
 
  const featuredMarketingData = useSelector((state)=>state.workpage?.featuredMarketing)
  console.log("featuredMarketingData",featuredMarketingData);
  
  return (
    <section className="bg-[#FFF4F0] ">
        <FeaturedIn bg={"#FFF4F0"} featuredMarketingData={featuredMarketingData}/>
    </section>
  );
};

export default FeaturedInSection;
