import React, { useEffect } from 'react'
import HeaderSection from './header'
import ContactChildSection from './ContactSection'
import ContactForm from './ContactForm'
import MapWithLocationCard from './MapWithLocationCard'
import { useDispatch } from 'react-redux'
import { fetchContactUsPageBanner } from '../../redux/contactUspageSlice'

const ContactSection = () => {

  const dispatch = useDispatch()

  useEffect(()=>{
    dispatch(fetchContactUsPageBanner())
  },[dispatch])

  return (
    <>
      <HeaderSection/>
      <ContactChildSection/>
      <ContactForm/>
      <MapWithLocationCard/>
    </>
  )
}

export default ContactSection
