import React, { useEffect } from 'react'
import HeaderSection from './header'
import WorkWithUs from './WorkWithUsSection'
import CareerSection from './CareerSection'
import FeaturedIn from '../../Component/Featured'
import { useDispatch, useSelector } from 'react-redux'
import { fetchConnectPage } from '../../redux/connectpageSlice'

const Connect = () => {
  const dispatch  = useDispatch()

  useEffect(()=>{
    dispatch(fetchConnectPage())
  },[dispatch])

  const featuredMarketingData = useSelector((state)=>state.connectpage?.featuredMarketing)

  return (
    <>
      <HeaderSection/>
      <WorkWithUs/>
      <CareerSection/>
      <FeaturedIn featuredMarketingData={featuredMarketingData}/>
    </>
  )
}

export default Connect
