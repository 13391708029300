import React, { useEffect, useState } from 'react';
import contactUs from "../../assets/contact-us.png";
import { useSelector } from 'react-redux';
import { handleRedirectClick } from '../../utils/redirect';

const LeftContainer = () => {
  const [showdata,setShowData] = useState({
    title1:"",
    title2:"",
    description:"",
    buttonPair:[]
  })
  const bannerData = useSelector((state) => state.homepage.banner);
  // console.log("bannerData", bannerData);
  
  useEffect(() => {
    if (bannerData) {
      const data = {
        title1: bannerData[0]?.title,
        title2: bannerData[0]?.title2,
        description: bannerData[0]?.description,
        buttonPair:bannerData[0]?.buttonPair
      }
      setShowData(data);
    }
  },[bannerData])
  return (
    <div className="flex h-full lg:h-auto items-center justify-between flex-col from-black to-red-900 font-['Urbanist'] px-4 md:px-0">
      <div className="mt-10 md:mt-1 md:ml-56 text-center md:text-left">
        <h1 className="text-2xl md:text-4xl font-bold text-black leading-snug">
          {/* Connecting Brand's   */}
          {showdata?.title1}
                   <br />
          <span className="text-[#E54F26] italic">
            {/* Creating Impact */}
            {showdata?.title2}
            </span>           
            <br />
          
        </h1>
        <p className="mt-4 text-sm md:text-lg text-black">
          {/* JHS Media One Stop solution to all of your digital marketing needs. */}
          {showdata?.description}
        </p>
        <div className="flex flex-wrap lg:flex-nowrap lg:space-x-4">
  {showdata?.buttonPair?.length > 0 ? showdata?.buttonPair.map((item, index) => (
    <div
     key={index} 
     className="Frame2 lg:w-[150px] mt-6 h-[45px] px-4 py-2 bg-[#e54f26] rounded-lg flex justify-center md:justify-start items-center cursor-pointer" 
      onClick={()=>{handleRedirectClick(item?.link)}}
    >
      <div className="ContactUs w-[111px] text-white text-lg font-semibold font-['Urbanist']"
      >
        {/* Let's Talk  */}
    {item?.name}
      </div>
      <img className="Subtract w-5 h-5 ml-2" src={contactUs} />
    </div>
  )) 
  : (
    <div className="Frame2 lg:w-[150px] mt-6 h-[45px] px-4 py-2 bg-[#e54f26] rounded-lg flex justify-center md:justify-start items-center">
      <div className="ContactUs w-[111px] text-white text-lg font-semibold font-['Urbanist']">
        Let's Talk 
      </div>
      <img className="Subtract w-5 h-5 ml-2" src={contactUs} />
    </div>
  )}
</div>

      </div>
    </div>
  );
};

export default LeftContainer;
