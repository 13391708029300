// userSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { APISURL } from '../contextApi/apiUrl';
import {api} from "../contextApi/api"


// API call for fetching users
export const fetchHomepage = createAsyncThunk('users/fetchHomepage', async () => {
    const data = await api.get(APISURL.GET_HOMEPAGE);
    // console.log("users",response);
    // const data = await response.json();
    return data.data;
});

const homepageSlice = createSlice({
  name: 'homepage',
  initialState: {
    banner: [],
    InfluencerServices:{},
    InfluencerMarketing:{},
    OurMilestones:{},
    FeaturedProjects:[],
    brands:[],
    ourProduct:{},
    whatWeDo:{},
    FeaturedMarketing:{},
    status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchHomepage.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchHomepage.fulfilled, (state, action) => {
        console.log("action.payload",action.payload);
        
        state.status = 'succeeded';
        state.banner = action.payload?.homePageBanners;
        state.InfluencerServices=action.payload?.InfluencerServices;
        state.InfluencerMarketing=action.payload?.InfluencerMarketing;
        state.OurMilestones=action.payload?.OurMilestones;
        state.FeaturedProjects=action.payload?.FeaturedProjects;
        state.brands=action.payload?.Brands;
        state.ourProduct=action.payload?.ourProduct;
        state.whatWeDo=action.payload?.whatWeDo;
        state.FeaturedMarketing=action.payload?.featuredMarketing;
      })
      .addCase(fetchHomepage.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default homepageSlice.reducer;
