import React, { useEffect } from 'react'
import DigitalInfluencerSection from './DigitalInfluencerSection'
import AboutUsSection from './AboutUsSection'
import FeaturedInSection from './FeaturedInSection'
import FeaturedIn from '../../Component/Featured'
import { useDispatch, useSelector } from 'react-redux'
import { fetchAboutpage } from '../../redux/aboutpageSlice'

const AboutUs = () => {

  const dispatch = useDispatch()

  useEffect(()=>{
    dispatch(fetchAboutpage())
  },[])

  const featuredMarketingData = useSelector((state)=>state.aboutpage.featuredMarketing)
  console.log("asfeaturedMarketingData",featuredMarketingData)


  return (
    <>
      <DigitalInfluencerSection/>
      <AboutUsSection/>
      <FeaturedInSection/>
    <FeaturedIn  featuredMarketingData={featuredMarketingData} />
    </>
  )
}

export default AboutUs
