// userSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { APISURL } from '../contextApi/apiUrl';
import {api} from "../contextApi/api"


// API call for fetching users
export const fetchAboutpage = createAsyncThunk('users/fetchaboutpage', async () => {
    const data = await api.get(APISURL.GET_ABOUTPAGE);

    return data.data;
});

const aboutpageSlice = createSlice({
  name: 'aboutpage',
  initialState: {
    banner: {},
    asFeaturedMarketing:{},
    featuredMarketing:{},
    status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAboutpage.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchAboutpage.fulfilled, (state, action) => {
        console.log("action.payload",action.payload);
        
        state.status = 'succeeded';
        state.banner = action.payload?.banner;
        state.asFeaturedMarketing = action.payload?.asFeaturedMarketing;
        state.featuredMarketing = action.payload?.featuredMarketing;
      })
      .addCase(fetchAboutpage.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default aboutpageSlice.reducer;
