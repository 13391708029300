import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../contextApi/api";
import { APISURL } from "../contextApi/apiUrl";

export const fetchContactUsPageBanner = createAsyncThunk("users/contactpagebanner",async()=>{
    try {
        const data = await api.get(APISURL.GET_CONTACTUS_PAGE_BANNER)
        return data.data
    } catch (error) {
        
    }
})


export const submitContactUsForm = createAsyncThunk(
    "users/submitcontactusform",
    async (formData, { rejectWithValue }) => {
      try {
        const response = await api.post(APISURL.CONTACTUS_PAGE_POST_CONTACTUS_FORM, formData);
        return response.data;
      } catch (error) {
        return rejectWithValue(error.response?.data || "Submission failed!");
      }
    }
  );


const contactUsPageSlice = createSlice({
    name:"contactuspage",
    initialState:{
        banner: {},
        status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
        error: null,
    },
    reducers:{},
    extraReducers:(builder)=>{  
        builder
        .addCase(fetchContactUsPageBanner.pending,(state)=>{
            state.status="loading"
        })
        .addCase(fetchContactUsPageBanner.fulfilled,(state,action)=>{
            state.status="succeeded";
            state.banner = action.payload?.banner;
        })
        .addCase(fetchContactUsPageBanner.rejected,(state,action)=>{
            state.status="failed";
            state.error=action.error.message
        })

         // Submit contact form
      .addCase(submitContactUsForm.pending, (state) => {
        state.status = "loading";
      })
      .addCase(submitContactUsForm.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.formResponse = action.payload;
      })
      .addCase(submitContactUsForm.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload || action.error.message;
      });
    }
})


export  default contactUsPageSlice.reducer;