import React, { useState } from 'react';
import arrowIcon from "../../assets/contact-us.png";
import logo from "../../assets/JHS_MEDIA_LOGO__WB-removebg.png";
import locationIcon from '../../assets/map-icon.png'; 
import callIcon from '../../assets/visit-icon.png';
import mapviewImg from '../../assets/mapview.png';
import { useDispatch } from 'react-redux';
import { submitContactUsForm } from '../../redux/contactUspageSlice';

const GetInTouch = () => {
  const dispatch = useDispatch();
  // State for form fields
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    phone: '',
    website: '',
    message: '',
    notRobot: false
  });

  // State for form errors
  const [errors, setErrors] = useState({});

 // Loading state for form submission
 const [loading, setLoading] = useState(false);

  // Handle input change
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  // Form validation
  const validateForm = () => {
    const newErrors = {};
    if (!formData.fullName) newErrors.fullName = 'Full Name is required';
    if (!formData.email) newErrors.email = 'Email is required';
    if (!formData.phone) newErrors.phone = 'Phone Number is required';
    if (!formData.message) newErrors.message = 'Message is required';
    if (!formData.notRobot) newErrors.notRobot = 'Please confirm you are not a robot';

    return newErrors;
  };

  // Handle form submission
  const handleSubmit = async(e) => {
    e.preventDefault();
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      setLoading(true); // Start loading
      // If no errors, submit form data
      console.log("Form data submitted:", formData);
      // You can call an API or handle the form submission here
      const formPayload = new FormData(); // Create a FormData object to handle file upload
      formPayload.append('fullName', formData.fullName);
      formPayload.append('email', formData.email);
      formPayload.append('phoneNumber', formData.phone);
      formPayload.append('message', formData.message);
      formPayload.append('countryCode', "+91");
      formPayload.append('subject', "no");
     
      try {
        await dispatch(submitContactUsForm(formPayload));
        setFormData({
          fullName: '',
          email: '',
          phone: '',
          website: '',
          message: '',
          notRobot: false
        });
        setErrors({});
      } catch (error) {
        console.error('Error submitting form:', error);
      } finally {
        setLoading(false); // Stop loading after submission
      }
    }
  };

  return (
    <div className="relative flex flex-col lg:flex-row">
      {/* Left Section */}
      <div className="relative w-full lg:w-6/12 bg-[#272727] text-white py-6 px-4 sm:py-8 sm:px-8 md:py-10 md:px-16 lg:py-20 lg:pl-48 lg:pr-0 flex flex-col justify-between font-['Urbanist']">
        <div className="absolute top-20 left-0 lg:-left-20 xl:-left-16 transform translate-x-0 lg:translate-x-1/2">
          <h1 className="text-2xl sm:text-3xl md:text-[48px] lg:text-[74px] font-[900] mb-4 sm:mb-6 z-10  uppercase text-[#272727]"
           style={{
             WebkitTextStroke: '2px #FFFFFF80',
            }}
          >
            GET IN TOUCH
          </h1>
        </div>
        <div className="pt-24 lg:pt-24">
          <p className="text-base sm:text-[15px] mb-4 sm:mb-6 font-[500]">
            When there are great ideas at stake, it’s always a good idea to share. Tell us how we can make it happen for you!
          </p>
          <div className="flex flex-col sm:flex-row items-start mb-3">
            <div className="mr-4">
              <img src={locationIcon} alt="JHS Media" className="h-6 sm:h-8" />
            </div>
            <div>
              <p className="font-[300] text-[18px]">Visit us:</p>
              <p className="text-sm sm:text-base font-[400]">
                Office No. 13 The Curve Building, Sheikh Zayed Road<br />
                <span className="block">Dubai, UAE</span>
              </p>
              <div className="flex items-center mt-2">
                <img src={mapviewImg} alt="Map view" className="h-8 w-8 mr-2" />
                <a href="#" className="text-sm sm:text-base font-[400]">Check on map!</a>
              </div>
            </div>
          </div>
          <div className="flex flex-col sm:flex-row sm:space-x-8 mb-3 mt-8">
            <div className="flex items-start mb-3 sm:mb-0">
              <div className="mr-4">
                <img src={callIcon} className="h-6 sm:h-8" />
              </div>
              <div>
                <p className="font-[300] text-[18px]">On call inquiry:</p>
                <p className="text-sm sm:text-base font-[400]">+91-988-600-0000</p>
              </div>
            </div>
            <div className="flex items-start">
              <div className="mr-4">
                <img src={callIcon} alt="JHS Media" className="h-6 sm:h-8" />
              </div>
              <div>
                <p className="font-[300] text-[18px]">For Assistance:</p>
                <p className="text-sm sm:text-base font-[400]">business@markie.in</p>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col lg:flex-row items-center lg:space-x-8 mt-auto lg:-mb-11 mb-2">
          <div className="mb-2 lg:mb-0 lg:-ml-3">
            <img src={logo} alt="JHS Media" className="h-16 sm:h-[70px]" />
          </div>
          <div className="flex space-x-2 sm:space-x-7">
            <a href="#" className="hover:text-orange-500 text-[20px] sm:text-base text-[#FFFFFF]">Brands</a>
            <span>|</span>
            <a href="#" className="hover:text-orange-500 text-[20px] sm:text-base text-[#FFFFFF]">Influencer</a>
            <span>|</span>
            <a href="#" className="hover:text-orange-500 text-[20px] sm:text-base text-[#FFFFFF]">Career</a>
          </div>
        </div>
      </div>

      {/* Right Section - Form */}
      <div className="w-full lg:w-7/12 bg-[#111111] text-white py-6 px-4 sm:py-8 sm:px-8 md:py-10 md:px-20 lg:py-8 lg:pr-40 flex flex-col justify-between font-['Urbanist']">
        <form className="p-4 sm:p-6 rounded-lg mb-6" onSubmit={handleSubmit}>
          {/* Full Name */}
          <div className="mb-3">
            <input
              type="text"
              name="fullName"
              value={formData.fullName}
              onChange={handleInputChange}
              placeholder="Full Name"
              className="w-full p-2 sm:p-3 rounded-lg border border-gray-700 bg-[#111111] text-white placeholder-[#B3B3B3]"
            />
            {errors.fullName && <p className="text-red-500 text-sm">{errors.fullName}</p>}
          </div>

          {/* Email Address */}
          <div className="mb-3">
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              placeholder="Email Address"
              className="w-full p-2 sm:p-3 rounded-lg border border-gray-700 bg-[#111111] text-white placeholder-[#B3B3B3]"
            />
            {errors.email && <p className="text-red-500 text-sm">{errors.email}</p>}
          </div>

          {/* Phone Number */}
          <div className="mb-3">
            <input
              type="number"
              name="phone"
              value={formData.phone}
              onChange={handleInputChange}
              placeholder="Phone Number"
              className="w-full p-2 sm:p-3 rounded-lg border border-gray-700 bg-[#111111] text-white placeholder-[#B3B3B3]"
            />
            {errors.phone && <p className="text-red-500 text-sm">{errors.phone}</p>}
          </div>

          {/* Website */}
          <div className="mb-3">
            <input
              type="text"
              name="website"
              value={formData.website}
              onChange={handleInputChange}
              placeholder="Website"
              className="w-full p-2 sm:p-3 rounded-lg border border-gray-700 bg-[#111111] text-white placeholder-[#B3B3B3]"
            />
          </div>

          {/* Message */}
          <div className="mb-3">
            <textarea
              name="message"
              value={formData.message}
              onChange={handleInputChange}
              placeholder="Message"
              className="w-full p-2 sm:p-3 rounded-lg border border-gray-700 bg-[#111111] text-white placeholder-[#B3B3B3] h-24 sm:h-32"
            ></textarea>
            {errors.message && <p className="text-red-500 text-sm">{errors.message}</p>}
          </div>

          {/* Not a Robot Checkbox */}
          <div className="flex items-center justify-between mb-3">
            <div className="flex items-center">
              <input
                type="checkbox"
                name="notRobot"
                checked={formData.notRobot}
                onChange={handleInputChange}
                id="not-robot"
                className="mr-2"
              />
              <label htmlFor="not-robot" className="text-sm sm:text-base">I’m not a robot</label>
              {errors.notRobot && <p className="text-red-500 text-sm ml-4">{errors.notRobot}</p>}
            </div>
            <button
              type="submit"
              className={`bg-[#E54F26] hover:bg-orange-600 text-[#FFFFFF] font-semibold py-2 sm:py-3 px-4 sm:px-6 rounded-lg flex items-center space-x-2 ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
              disabled={loading}
            >
               {loading ? (
                <span className='tracking-[2px]'>Submitting...</span>
              ) : (
                <>
                  <span className='tracking-[2px]'>Submit</span>
                  <img src={arrowIcon} alt="Arrow" className="w-5 h-5" />
                </>
              )}
              {/* <span className='tracking-[2px]'>Submit</span>
              <img src={arrowIcon} alt="Arrow" className="w-5 h-5" /> */}
            </button>
          </div>
        </form>

        <div className="text-[20px] sm:text-base text-center mt-auto mb-2 text-[#FFFFFF] font-[400]">
          © JHS Media Marketing 2024. All rights reserved.
        </div>
      </div>
    </div>
  );
};

const ContactPage = () => {
  return (
    <div>
      <GetInTouch />
    </div>
  );
};

export default ContactPage;
